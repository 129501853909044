<i18n locale="th" lang="yaml" >
page.title : "Privacy Policy"
logout.success.title : "ออกจากระบบเรียบร้อย"
logout.success.content : "คุณ {name} ออกจากระบบ @:common.application.title เรียบร้อย"
consent.title : "กรุณาอ่านและยอมรับเงื่อนไขการใช้งาน"
consent.agree : "ยอมรับเงื่อนไขการใช้งาน"
consent.disagree : "ไม่ยอมรับเงื่อนไขการใช้งาน"

</i18n>

<template>
    <a-modal
			visible="visible"
			:title="$t('consent.title')"
			:ok-text="$t('consent.agree')"
			:cancel-text="$t('consent.disagree')"
			:closable="false"
			:centered="true"
			:mask-closable="false"
			width="100%"
			@ok="handleAgree"
      @cancel="handleDecline">
     	<PrivacyPolicyContent/>
    </a-modal>
</template>

<script>

import store from '@store/store'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import PrivacyPolicyContent from "@/src/components/common/PrivacyPolicyContent.vue"

export default {
	components : {
		PrivacyPolicyContent
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	mixins : [LoginInfoMixin,] ,
	methods: {
		...mapActions('auth',['logout', 'updateCurrentUser']),
		...mapGetters('app',['changeDefaultPathByDevice']),
		handleAgree(e) {
			const userId = store.getters['auth/authorizeDomain'].userId
			const formData = {
				'user_id' : userId
			}
			axios.post('/api/users/consent', formData).then((response) => {
				this.$router.push({name: this.changeDefaultPathByDevice()})
			})
		},
		handleDecline(e) {
			const name = this.currentUser.full_name;
			const successTitle = this.$t('logout.success.title');
			const successContent = this.$t('logout.success.content',{"name" : name});
			this.logout().then(() => {
				this.$notification.success({
					message :  successTitle,
					description : successContent,
				});
				this.$router.push({name: 'login'});
			})
		},
	},
}
</script>

<style lang="less" scoped>


</style>
